export default {
  routes: [
    {
      name: 'menus',
      displayname: 'General Menus',
      icon: ''
    },
    {
      name: 'items',
      displayname: 'Items',
      icon: ''
    },
    {
      name: 'categories',
      displayname: 'Categories',
      icon: ''

    },
    {
      name: 'menu',
      displayname: 'Menus',
      icon: ''

    },
    {
      name: 'modifiers',
      displayname: 'Modifiers',
      icon: ''
    },
    {
      name: 'offers',
      displayname: 'Offers',
      icon: ''
    },
    {
      name: 'allergenIcons',
      displayname: 'Allergen Icons',
      icon: ''
    },
    {
      name: 'orders',
      displayname: 'Orders',
      icon: '<i class="fa fa-shopping-basket va-icon"></i>',
    },
    {
      name: 'reviewsOverview',
      displayname: 'Review Overview',
      icon: '<i class="fas fa-comments va-icon fas fa-comments"></i>'
    },
    {
      name: 'reviewQuestions',
      displayname: 'Review Questions',
      icon: ''
    },
    {
      name: 'reviews',
      displayname: 'Reviews',
      icon: ''
    },
    {
      name: 'itemreviews',
      displayname: 'Item Reviews',
      icon: ''
    },
    {
      name: 'auotreply_reviews',
      displayname: 'Auto-reply Reviews',
      icon: ''
    },
    {
      name: 'stores',
      displayname: 'Stores',
      icon: '<i class="fas fa-store va-icon"></i>'
    },
    {
      name: 'storelist',
      displayname: 'Store List',
      icon: ''

    },
    {
      name: 'managestores',
      displayname: 'Manage Stores',
      icon: ''
    },
    {
      name: 'store-status-list',
      displayname: 'Store Status',
      icon: ''
    },
    {
      name: 'giftvouchers',
      displayname: 'Gift-cards',
      icon: '<i class="fa fa-gift va-icon fa fa-gift"></i>',
    },
    {
      name: 'giftcardstype',
      displayname: 'Gift-cards Type',
      icon: ''
    },
    {
      name: 'giftcards',
      displayname: 'Giftcards',
      icon: ''
    },
    {
      name: 'payments',
      displayname: 'Giftcards Payments',
      icon: ''
    },
    {
      name: 'reports',
      displayname: 'Giftcards Reports',
      icon: ''
    },
    {
      name: 'analytics',
      displayname: 'Analytics',
      icon: '<i class="fas fa-chart-line va-icon"></i>'
    },
    {
      name: 'loyalty',
      displayname: 'Loyalty',
      icon: ''
    },
    {
      name: 'loyalty_rules',
      displayname: 'loyalty Rules',
      icon: ''
    },
    {
      name: 'loyalty_activities',
      displayname: 'Loyalty Activities',
      icon: ''
    },
    {
      name: 'loyalty_customers',
      displayname: 'Loyalty Customers',
      icon: ''
    },
    {
      name: 'usermanagement',
      displayname: 'Manage Users',
      icon: '<i class="fas fa-users-cog va-icon"></i>',
    },
    {
      name: 'staffs',
      displayname: 'Staffs',
      icon: ''
    },
    {
      name: 'roles',
      displayname: 'Roles',
      icon: ''
    },
    {
      name: 'settings',
      displayname: 'Settings',
      icon: '<i class="fa fa-cog va-icon fa fa-cog"></i>',
    },
    {
      name: 'thirdpartysettings',
      displayname: 'Third Party Settings',
      icon: ''
    },
    {
      name: 'pesapalsettings',
      displayname: 'Pesapal Settings',
      icon: ''
    },
    {
      name: 'appsettings',
      displayname: 'App Settings',
      icon: ''
    },
    {
      name: 'giftvouchersettings',
      displayname: 'Giftvoucher/Loyalty Settings',
      icon: ''
    },

    {
      name: 'layoutsettings',
      displayname: 'Layout Settings',
      icon: ''
    },
    {
      name: 'shippingoptions',
      displayname: 'Shipping Options',
      icon: ''
    },
    {
      name: 'navmenus',
      displayname: 'Navigation Menus',
      icon: ''
    },
    {
      name: 'posconfigs',
      displayName: 'Pos Configs',
      icon: ''
    },
    {
      name: 'all_customers',
      displayname: 'Admin Customers',
      icon: '<i class="fas fa-users va-icon"></i>'
    },
    {
      name: 'third_party_payment',
      displayname: 'Order Payments(third party)',
      icon: '<i class="fas fa-hand-holding-usd va-icon"></i>',
    },
    {
      name: 'third_party_store',
      displayname: 'Payment Store Link',
      icon: ''
    },
    {
      name: 'failed_orders',
      displayname: 'Failed Orders',
      icon: '<i class="fa fa-minus-circle va-icon"></i>'
    },
    {
      name: 'dashboard',
      displayname: 'Dashboard',
      icon: ''
    },
    {
      name: 'users',
      displayname: 'System users',
      icon: ''
    },
    {
      name: 'faqs',
      displayname: 'Faqs',
      icon: ''
    },
    {
      name: 'gift_redemptions',
      displayName: 'Redemptions',
      icon: ''
    }
  ],
}

